import { Form, Input, Select } from "antd";

export default () => {
  return [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Регистрийн дугаар",
      name: "regno",
      rules: [{ required: true, message: "Регистрийн дугаар оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Төрөл",
      name: "type",
      type: "select",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Commercial", value: "Commercial" },
          { label: "Non Commercial", value: "Non Commercial" },
        ],
      },
    },
    {
      label: "Нэмэлт тайлбар",
      name: "descr",
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];
};
