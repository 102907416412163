import { message } from 'antd';
import ls from 'utils/ls';

const actions = (dispatch) => ({
  login: (token, userInfo) => {
    ls.set('userInfo', userInfo)
    dispatch({type: 'LOGIN', token: token, userInfo: userInfo})
  },
  logout: async () => {
    ls.remove('userInfo')
    dispatch({type: 'LOGOUT'})
  },
  changeTabIndexSetting: async (index) => {
    dispatch({type: 'CHANGE_TAB_INDEX_SETTING', index})
  },
  changeLogo: async (image) => {
    dispatch({type: 'CHANGE_LOGO', image})
  },
  handleSidebarCollapse: async (isCollapsed) => {
    if(isCollapsed){
      document.documentElement.style.setProperty('--sidebar-width', '75px')
    }
    else{
      document.documentElement.style.setProperty('--sidebar-width', '250px')
    }
    dispatch({type: 'CHANGE_SIDEBAR_WIDTH', sidebarCollapsed: isCollapsed})
  },
  onSuccess: (info) => {
    message.success(info)
  },
  onError: (info) => {
    message.error(info)
  }
})

export default actions
