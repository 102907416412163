import React, { useState, useEffect } from "react";
import { Button, Modal, Upload, Table, Select } from "antd";
import * as XLSX from "xlsx";
import { instance } from "utils/axios";
const { Option } = Select;

const CustomSource = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [parsedHeaders, setParsedHeaders] = useState([]);
  const [headerMappings, setHeaderMappings] = useState({});
  const [excelData, setExcelData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const columns = [
    { title: "Регистр", dataIndex: "regno", key: "regno" },
    { title: "Нэр", dataIndex: "name", key: "name" },
    { title: "Горим", dataIndex: "mode", key: "mode" },
    { title: "Огноо", dataIndex: "cDate", key: "cDate" },
    { title: "Бараа код", dataIndex: "itemCode", key: "itemCode" },
    { title: "Бараа нэр", dataIndex: "itemName", key: "itemName" },
    { title: "Зориулалт", dataIndex: "dedication", key: "dedication" },
    { title: "Үйлд.он", dataIndex: "productionYear", key: "productionYear" },
    { title: "Марк", dataIndex: "mark", key: "mark" },
    { title: "Нэгж", dataIndex: "unit", key: "unit" },
    { title: "Тоо", dataIndex: "qty", key: "qty" },
    { title: "Үнэ.$", dataIndex: "priceDollar", key: "priceDollar" },
    { title: "Гааль.₮", dataIndex: "custom", key: "custom" },
    { title: "Онцгой.₮", dataIndex: "special", key: "special" },
    { title: "Албан.₮", dataIndex: "tax", key: "tax" },
    { title: "НӨАТ.₮", dataIndex: "vat", key: "vat" },
    { title: "Нийт.тат.₮", dataIndex: "totalTaxes", key: "totalTaxes" },
    { title: "Гарал.улс", dataIndex: "country", key: "country" },
    { title: "Илгээгч улс", dataIndex: "sentCountry", key: "sentCountry" },
    { title: "Үнэ.₮", dataIndex: "priceTugrug", key: "priceTugrug" },
    { title: "Илгээгч", dataIndex: "sender", key: "sender" },
  ];

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/import_source`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleImport = async (file) => {
    setLoading(true);
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    // Read the first sheet
    const worksheet1 = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData1 = XLSX.utils.sheet_to_json(worksheet1, { header: 1 });

    // Read the second sheet
    const worksheet2 = workbook.Sheets[workbook.SheetNames[1]];
    const jsonData2 = XLSX.utils.sheet_to_json(worksheet2, { header: 1 });

    // Combine the headers from both sheets (assuming they are the same or compatible)
    const headers = jsonData1[0]; // Use headers from the first sheet
    const jsonData = [...jsonData1.slice(1), ...jsonData2.slice(1)];
    setParsedHeaders(headers); // Save these headers for the modal

    // Auto-fill mappings where the header matches a column title
    const initialMappings = {};
    headers.forEach((header) => {
      const matchingColumn = columns.find(
        (column) => column.title.trim() === header.trim()
      );
      if (matchingColumn) {
        initialMappings[header] = matchingColumn.dataIndex;
      }
    });
    setHeaderMappings(initialMappings);
    setExcelData(jsonData.slice(1)); // Save the rest of the data
    setShowModal(true); // Show the modal for header mapping
    setLoading(false);
  };

  const handleMappingConfirm = () => {
    const formattedData = excelData.map((row) => {
      const formattedRow = {};
      parsedHeaders.forEach((header, index) => {
        const dbColumn = headerMappings[header];
        if (dbColumn) {
          formattedRow[dbColumn] = row[index]; // Use index to map the correct value
        }
      });
      return formattedRow;
    });

    instance({
      method: "post",
      url: `/import_source/mass`,
      data: formattedData,
    })
      .then((res) => {
        fetchData(); // Refresh the table data after successful upload
      })
      .catch((err) => {
        console.error("Error uploading data:", err);
      })
      .finally(() => {
        setLoading(false);
        setShowModal(false); // Close the modal after processing
      });
  };
const handleSave =()=>{
  instance({
    method: "post",
    url: `/import_source/insertmain`,
  })
    .then((res) => {
      console.log(res.data)
      fetchData(); // Refresh the table data after successful upload
    })
    .catch((err) => {
      console.error("Error uploading data:", err);
    })
    .finally(() => {
      setLoading(false);
      setShowModal(false); // Close the modal after processing
    });
}
  const renderHeaderMappingModal = () => (
    <Modal
      title="Map Excel Headers to Database Columns"
      visible={showModal}
      onCancel={() => setShowModal(false)}
      onOk={handleMappingConfirm}
    >
      {parsedHeaders.map((header, index) => (
        <div
          key={index}
          className="header-mapping-row"
          style={{ marginBottom: "10px" }}
        >
          <span>{header}</span>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Column"
            value={headerMappings[header] || ""}
            onChange={(value) =>
              setHeaderMappings({ ...headerMappings, [header]: value })
            }
          >
            {columns.map((column) => (
              <Option key={column.dataIndex} value={column.dataIndex}>
                {column.title}
              </Option>
            ))}
          </Select>
        </div>
      ))}
    </Modal>
  );

  const uploadProps = {
    beforeUpload: (file) => {
      handleImport(file);
      return false; // Prevent default upload behavior
    },
    multiple: false,
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="col-span-6 md:flex items-center gap-2">
        <Upload {...uploadProps}>
          <Button type="primary">Upload Excel File</Button>
        </Upload>
        {renderHeaderMappingModal()}
        <Button type="danger" onClick={handleSave}>Remove Duplicate & Delete Data </Button>
      </div>
      <div className="border bg-white border-gray-200 rounded-lg shadow">
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={true}
          rowKey={(record, index) => index}
        />
      </div>
    </div>
  );
};

export default CustomSource;
