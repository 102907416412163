import {
  Dashboard,
  Employee,
  CustomSource,
  Competitor,
  MainSource,
} from "modules/admin";

export default [
  {
    name: "Хянах самбар",
    path: "/",
    element: <Dashboard />,
    role: ["Админ", "Удирдлага", "Менежер"],
  },
  {
    name: "Import",
    path: "/import",
    element: <CustomSource />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Main",
    path: "/main",
    element: <MainSource />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Өрсөлдөгч",
    path: "/competitor",
    element: <Competitor />,
    role: ["CEO", "Админ", "Менежер"],
  },

  {
    name: "Ажилчид",
    path: "/employee",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <Employee />,
  },
  // Тохирг
];
