import React, { useState, useEffect } from "react";
import { Table, Select, Tag } from "antd";
import { instance } from "utils/axios";

const { Option } = Select;

const MainSource = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [competitors, setCompetitors] = useState([]);
const [categories, setCategories] = useState([]);
const [subCategories, setSubCategories] = useState([]);
  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
    fetchCompetitors(); // Fetch competitor options
     fetchCategories();
     fetchSubCategories();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/main/list`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCompetitors = () => {
    instance({
      method: "get",
      url: `/competitor/list`, // Replace with the correct API endpoint
    })
      .then((res) => {
        setCompetitors(res.data);
      })
      .catch((err) => {
        console.error("Error fetching competitors:", err);
      });
  };

const fetchCategories = () => {
  instance({
    method: "get",
    url: `/categories`, // Replace with the correct API endpoint
  })
    .then((res) => {
      setCategories(res.data);
    })
    .catch((err) => {
      console.error("Error fetching categories:", err);
    });
};

const fetchSubCategories = () => {
  instance({
    method: "get",
    url: `/subcategories`, // Replace with the correct API endpoint
  })
    .then((res) => {
      setSubCategories(res.data);
    })
    .catch((err) => {
      console.error("Error fetching subcategories:", err);
    });
};
  const handleSetCompetitor = (value, row) => {
    // Handle updating the competitorId in the backend or state
    console.log("Updated Competitor ID:", value, "for row:", row);

    // Example: You can update the state or make an API call here
    instance({
      method: "post",
      url: `/main/update`, // Replace with the correct API endpoint
      data: {
        ...row,
        competitorId: value,
      },
    })
      .then((res) => {
        console.log("Updated successfully:", res);
        fetchData(); // Optionally re-fetch data to reflect the changes
      })
      .catch((err) => {
        console.error("Error updating competitorId:", err);
      });
  };
const handleSetCategory = (value, row) => {
  // Update the categoryId in the row
  console.log("Updated Category ID:", value, "for row:", row);
  instance({
    method: "post",
    url: `/main/update`, // Replace with the correct API endpoint
    data: {
      ...row,
      categoryId: value,
      subCategoryId: null, // Reset subCategoryId if category changes
    },
  })
    .then((res) => {
      console.log("Updated successfully:", res);
      fetchData(); // Optionally re-fetch data to reflect the changes
    })
    .catch((err) => {
      console.error("Error updating categoryId:", err);
    });
};

const handleSetSubCategory = (value, row) => {
  // Update the subCategoryId in the row
  console.log("Updated SubCategory ID:", value, "for row:", row);
  instance({
    method: "post",
    url: `/main/update`, // Replace with the correct API endpoint
    data: {
      ...row,
      subCategoryId: value,
    },
  })
    .then((res) => {
      console.log("Updated successfully:", res);
      fetchData(); // Optionally re-fetch data to reflect the changes
    })
    .catch((err) => {
      console.error("Error updating subCategoryId:", err);
    });
};

const columns = [
  { title: "Жил", dataIndex: "cYear", key: "cYear" },
  { title: "Улирал", dataIndex: "quarter", key: "quarter" },
  {
    title: "Байгууллага",
    dataIndex: "competitorId",
    key: "competitorId",
    render: (text, row) => (
      <Select
        className="mb-0 w-[150px]"
        value={row.competitorId}
        options={competitors.map((comp) => ({
          label: comp.name,
          value: comp.id,
        }))}
        allowClear
        onChange={(e) => handleSetCompetitor(e, row)}
      />
    ),
  },
  {
    title: "Ангилал",
    dataIndex: "categoryId",
    key: "categoryId",
    render: (text, row) => (
      <Select
        className="mb-0 w-[150px]"
        value={row.categoryId}
        options={categories.map((cat) => ({ label: cat.name, value: cat.id }))}
        allowClear
        onChange={(e) => handleSetCategory(e, row)}
      />
    ),
  },
  {
    title: "Дэд ангилал",
    dataIndex: "subCategoryId",
    key: "subCategoryId",
    render: (text, row) => {
      // Filter subcategories based on selected categoryId
      const filteredSubCategories = subCategories.filter(
        (subCat) => subCat.categoryId === row.categoryId
      );

      return (
        <Select
          className="mb-0 w-[150px]"
          value={row.subCategoryId}
          options={filteredSubCategories.map((subCat) => ({
            label: subCat.name,
            value: subCat.id,
          }))}
          allowClear
          onChange={(e) => handleSetSubCategory(e, row)}
        />
      );
    },
  },
  {
    title: "Горим",
    dataIndex: "mode",
    key: "mode",
  },
  {
    title: "Огноо",
    dataIndex: "cDate",
    key: "cDate",
  },
  {
    title: "Бараа код",
    dataIndex: "itemCode",
    key: "itemCode",
  },
  {
    title: "Бараа нэр",
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: "Зориулалт",
    dataIndex: "dedication",
    key: "dedication",
  },
  {
    title: "Үйлд.он",
    dataIndex: "productionYear",
    key: "productionYear",
  },
  {
    title: "Марк",
    dataIndex: "mark",
    key: "mark",
  },
  {
    title: "Нэгж",
    dataIndex: "unit",
    key: "unit",
  },
  {
    title: "Тоо",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Үнэ.$",
    dataIndex: "priceDollar",
    key: "priceDollar",
  },
  {
    title: "Гааль.₮",
    dataIndex: "custom",
    key: "custom",
  },
  {
    title: "Онцгой.₮",
    dataIndex: "special",
    key: "special",
  },
  {
    title: "Албан.₮",
    dataIndex: "tax",
    key: "tax",
  },
  {
    title: "НӨАТ.₮",
    dataIndex: "vat",
    key: "vat",
  },
  {
    title: "Нийт.тат.₮",
    dataIndex: "totalTaxes",
    key: "totalTaxes",
  },
  {
    title: "Гарал.улс",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Илгээгч улс",
    dataIndex: "sentCountry",
    key: "sentCountry",
  },
  {
    title: "Үнэ.₮",
    dataIndex: "priceTugrug",
    key: "priceTugrug",
  },
  {
    title: "Илгээгч",
    dataIndex: "sender",
    key: "sender",
  },
];

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="border bg-white border-gray-200 rounded-lg shadow">
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={true}
          rowKey={(record, index) => index}
        />
      </div>
    </div>
  );
};

export default MainSource;
