import React, { useEffect, useState } from "react";
import { Button, Modal, Tag, Form as AntForm } from "antd";
import { EditOutlined, SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import fields from "./fields";

function Competitor() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [form] = AntForm.useForm();
  const [filterForm] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/competitor?pageindex=${currentPage}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "regno",
      key: "regno",
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Тайлбар",
      dataIndex: "descr",
      key: "descr",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: "/competitor",
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/competitor",
        data: {
          ...values,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const searchFields = [
    {
      label: "Нэр",
      name: "name",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Регистер",
      name: "regno",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];

  const handleFilterSubmit = (values) => {
    setSearchLoading(true);
    instance({
      method: "get",
      url: `/competitor?pageindex=0&pagesize=${20}&name=${
        values.name ? values.name : ""
      }&regno=${values.regno ? values.regno : ""}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setSearchLoading(false));
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow mb-5 w-1/2">
        <div className="text-xl leading-none mb-3">Хайлт</div>
        <Form
          form={filterForm}
          fields={searchFields}
          length={data.length}
          layout="vertical"
          onFinish={handleFilterSubmit}
          className="gap-5"
        >
          <div className="col-span-12 flex justify-end gap-2 mt-4">
            <Button
              className="flex items-center"
              size="small"
              type="default"
              htmlType="submit"
              onClick={() => filterForm.submit()}
              loading={searchLoading}
            >
              <SearchOutlined />
              Хайх
            </Button>
            <Button
              className="flex items-center"
              size="small"
              type="default"
              onClick={() => filterForm.resetFields()}
            >
              <ClearOutlined />
              Цэвэрлэх
            </Button>
          </div>
        </Form>
      </div>
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title={"Өрсөлдөгч"}
        handleAdd={handleAdd}
      />
      <Modal
        title={editData ? "Өрсөлдөгч мэдээлэл засах" : "Шинэ өрсөлдөгч"}
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields()}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Competitor;
